import * as React from 'react';  
import Axios from 'axios'; 
export const url =   "https://www.smestoreph.com" //  "http://192.168.1.148:9091"  // 
export const urlFirebase =  "http://192.53.114.35:8080" // "https://www.smestoreph.com"  //
export const urlSupBase = "https://www.smestoreph.com"  // "http://192.168.1.148:3001" // 
export const axios = Axios.create({
    baseURL:url,
    timeOut:3000,
    headers: {
        "Content-Type": "application/json"
    }
});
export const productStats = Axios.create({
    baseURL:urlFirebase,
    timeOut:3000,
    headers: {
        "Content-Type": "application/json"
    }
});

export const productLike = Axios.create({
    baseURL:urlSupBase,
    timeOut:3000,
    headers: {
        "Content-Type": "application/json"
    }
}); 